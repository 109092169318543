import React from 'react';

const NavBar: React.FC = () => {
  return (
    <nav className="bg-gray-800 text-white p-4 flex justify-between items-center">
      <a href="https://naturaev.com/"> {/* Use absolute URL for the main site */}
        <img src="/assets/gwhite.png" alt="Natura EV Logo" className="h-10" />
      </a>
      <a href="https://naturaev.com/" className="hidden md:block"> {/* Adjust URL if needed */}
        <p>Natura EV</p> {/* Responsive visibility change */}
      </a>
      <ul className="flex space-x-4">
        <li><a href="https://naturaev.com/areas">Areas</a></li> {/* Use absolute URLs */}
        <li><a href="https://naturaev.com/rebates1">Rebates</a></li> {/* Ensure correct path */}
        <li><a href="https://naturaev.com/pricing">Pricing</a></li>
        <li><a href="https://naturaev.com/contactus">Contact Us</a></li>
      </ul>
    </nav>
  );
};

export default NavBar;
