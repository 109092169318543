import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 px-8 text-center sm:text-left">
        {/* Customer Service Section */}
        <div>
          <h2 className="font-bold text-lg mb-3">Customer Service</h2>
          <ul>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/returns">Returns</Link></li>
            <li><Link to="/shipping">Shipping Policy</Link></li>
            <li><Link to="/subscribe">Subscribe</Link></li>
          </ul>
        </div>

        {/* Rebates Section */}
        <div>
          <h2 className="font-bold text-lg mb-3">Rebates</h2>
          <ul>
            <li><Link to="/rebates1">Long Island</Link></li>
            <li><Link to="/rebates1">New York</Link></li>
            <li><Link to="/rebates1">New Jersey</Link></li>
            <li><Link to="/rebates1">Delaware</Link></li>
            <li><Link to="/rebates1">Philadelphia</Link></li>
          </ul>
        </div>

        {/* Manufacturers Section */}
        <div>
          <h2 className="font-bold text-lg mb-3">Manufacturers</h2>
          <ul>
            <li><Link to="/terms">EverCharge</Link></li>
            <li><Link to="/privacy-policy">ChargePoint</Link></li>
            <li><Link to="/privacy-policy">Leviton</Link></li>
            <li><Link to="/privacy-policy">RAB</Link></li>
            <li><Link to="/privacy-policy">Enelway</Link></li>
            <li><Link to="/privacy-policy">Flo</Link></li>
            <li><Link to="/privacy-policy">Tesla</Link></li>
          </ul>
        </div>

        {/* Company Info Section */}
        {/* <div>
          <h2 className="font-bold text-lg mb-3">Company Info</h2>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/careers">Careers</Link></li>
            <li><Link to="/sitemap">Site Map</Link></li>
          </ul>
        </div> */}

        {/* Contact Number and Social Media Icons */}
        <div className="col-span-4 flex flex-col items-center justify-center mt-6">
            <a href="https://naturaev.com/">
                <img src="/assets/gwhite.png" alt="Natura EV Logo" className="mx-auto mb-6"/>
            </a>
            <p>(631) 306-4602</p>
            <a href="mailto:info@naturaev.com" className="hover:underline">info@naturaev.com</a>
            <div className="flex justify-center space-x-4 mt-4">
                <a href="https://twitter.com"><i className="fa fa-twitter"></i></a>
                <a href="https://facebook.com"><i className="fa fa-facebook"></i></a>
                <a href="https://instagram.com"><i className="fa fa-instagram"></i></a>
            </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
