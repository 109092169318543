import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const WelcomeSection = () => {
  return (
    <HelmetProvider>
      <div>
      <Helmet>
          <title>Expert EV Charger Installations in North Hempstead NY</title>
          <meta name="description" content="Get professional EV charger installation services in Hempstead. Tailored solutions with top-notch equipment and certified technicians. Contact us for a free quote today!" />
        </Helmet>
    <section className="bg-green-200 text-gray-800 py-12 px-4">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-semibold mb-6">Expert EV Charger Installations in North Hempstead</h2>
        <p className="max-w-2xl mx-auto mb-6">Your trusted partner in EV charging solutions. We provide comprehensive installation and service across North Hempstead and nearby towns.</p>
        <a href="https://www.naturaev.com" className="bg-black text-white font-bold py-2 px-6 rounded hover:bg-green-700 transition duration-300 inline-block text-center">
          Inquire for a free quote
        </a>
      </div>
    </section>
    </div>
    </HelmetProvider>
  );
};

export default WelcomeSection;
