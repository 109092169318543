import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/colonybeginning/Home'; // Assuming Home includes everything for the landing page
import About from './components/About';
import Services from './components/Services';
import ExploreNeighborhoods2 from './components/ExploreNeighborhoods2';
import Locations from './components/Locations';
import ContentHero from './components/colonybeginning/ContentHero';
import RebatesHero from './components/RebatesHero';
import ChargerSelection from './components/colonybeginning/ChargerSelection';
import CivilWorkSection from './components/CivilWorkSection';
import CenterportChargers from './components/towns/CenterportChargers';
import Rebates from './components/colonybeginning/Rebates1';
import Centerport from './components/towns/Centerport';
import Pricing from './components/Pricing';
import ContactUs from './components/ContactUs';

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <NavBar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/areas" element={<ExploreNeighborhoods2 />} />
            <Route path="/services" element={<Services />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/content-section" element={<ContentHero />} />
            <Route path="/rebates" element={<RebatesHero/>} />
            <Route path="/charger-selection" element={<ChargerSelection />} />
            <Route path="/civil-work" element={<CivilWorkSection />} />
            <Route path="/centerport-chargers" element={<CenterportChargers />} />
            <Route path="/rebates1" element={<Rebates />} />
            <Route path="/towns/:townName" element={<Centerport />} />
            <Route path="/pricing" element={<Pricing />} />
          <Route path="/contactus" element={<ContactUs />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
