import React from 'react';
import { Link, useParams } from 'react-router-dom';

// Define the Town interface
interface Town {
  name: string;
  image: string;
  externalUrl: string; 
  // path: string;
}

// Define the areas object using the Town interface
const areas: Record<string, Town[]> = {
  "Town of North Hempstead": [
    { name: "Albertson", image: "./assets/albertson.jpg", externalUrl: "https://naturaev.com/towns/Albertson" },
    { name: "Carle Place", image: "./assets/carle-place.jpg", externalUrl: "https://naturaev.com/towns/Carle%20Place" },
    { name: "Floral Park", image: "./assets/floral-park.jpg", externalUrl: "https://naturaev.com/towns/Floral%20Park" },
    { name: "Glenwood Landing", image: "./assets/glenwood-landing.jpg", externalUrl: "https://naturaev.com/towns/Glenwood%20Landing" },
    { name: "Great Neck", image: "./assets/great-neck.jpg", externalUrl: "https://naturaev.com/towns/Great%20Neck" },
    { name: "Greenvale", image: "./assets/greenvale.jpg", externalUrl: "https://naturaev.com/towns/Greenvale" },
    { name: "Kings Point", image: "./assets/kings-point.jpg", externalUrl: "https://naturaev.com/towns/Kings%20Point" },
    { name: "Manhasset", image: "./assets/manhasset.webp", externalUrl: "https://naturaev.com/towns/Manhasset" },
    { name: "Mineola", image: "./assets/mineola.webp", externalUrl: "https://naturaev.com/towns/Mineola" },
    { name: "New Hyde Park", image: "./assets/new-hyde-park.jpg", externalUrl: "https://naturaev.com/towns/New%20Hyde%20Park" },
    { name: "North New Hyde Park", image: "./assets/north-new-hyde-park.jpg", externalUrl: "https://naturaev.com/towns/North%20New%20Hyde%20Park" },
    { name: "Old Westbury", image: "./assets/old-westbury.jpg", externalUrl: "https://naturaev.com/towns/Old%20Westbury" },
    { name: "Port Washington", image: "./assets/port-washington.jpg", externalUrl: "https://naturaev.com/towns/Port%20Washington" },
    { name: "Roslyn", image: "./assets/roslyn.jpg", externalUrl: "https://naturaev.com/towns/Roslyn" },
    { name: "Roslyn Heights", image: "./assets/roslyn-heights.jpg", externalUrl: "https://naturaev.com/towns/Roslyn%20Heights" },
    { name: "Sands Point", image: "./assets/sands-point.webp", externalUrl: "https://naturaev.com/towns/Sands%20Point" },
    { name: "Westbury", image: "./assets/westbury.jpg", externalUrl: "https://naturaev.com/towns/Westbury" },
    { name: "Williston Park", image: "./assets/williston-park.jpg", externalUrl: "https://naturaev.com/towns/Williston%20Park" },
  ],
};

const TownPage = () => {
  let { townName } = useParams<{ townName: string }>();
  // Optionally use townName to fetch town details or handle it dynamically
  return (
    <div>
      <h1>{townName}</h1>
      {/* Additional logic to display town details */}
    </div>
  );
};

const ExploreNeighborhoods = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">EV Charging North Hempstead</h1>
      {Object.keys(areas).map((county) => (
        <div key={county} className="mb-5">
          <h2 className="text-2xl font-bold text-left mb-3">{county}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {areas[county].map((town) => (
              <div key={town.name} className="rounded overflow-hidden shadow-lg">
                <a href={town.externalUrl} target="_blank" rel="noopener noreferrer">
                  <img src={town.image} alt={town.name} className="w-full h-48 object-cover" />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">{town.name}</div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExploreNeighborhoods;


// const ExploreNeighborhoods = () => {
//   return (
//     <div className="container mx-auto px-4 py-12">
//       <h1 className="text-4xl font-bold text-center mb-8">Explore Long Island</h1>
//       {Object.keys(areas).map((county) => (
//         <div key={county} className="mb-5">
//           <h2 className="text-2xl font-bold text-left mb-3">{county}</h2>
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
//             {areas[county].map((town) => (
//               <div key={town.name} className="rounded overflow-hidden shadow-lg">
//                 <Link to={`/towns/${encodeURIComponent(town.name.replace(/\s+/g, '-').toUpperCase())}`}>
//                   <img src={town.image} alt={town.name} className="w-full h-48 object-cover" />
//                   <div className="px-6 py-4">
//                     <div className="font-bold text-xl mb-2">{town.name}</div>
//                   </div>
//                 </Link>
//               </div>
//             ))}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ExploreNeighborhoods;
